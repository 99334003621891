import moment from 'moment-timezone';
import store from '@/store';

import { WEEKDAYS } from '@/utils/constants/common/datetime';

export const DEFAULT_DATE_FORMAT = 'MM/DD/YYYY';

export const isDateFormat = str => str.match(/^(\d{2})\/(\d{2})\/(\d{4})$/);

/**
 * Formats a date value into a specified format, with optional timezone conversion.
 *
 * @param {Object} params - An object containing the parameters for formatting.
 * @param {string|number|Date} params.value - The date value to format. This can be a string, a number (timestamp), or a Date object.
 * @param {string} [params.outputFormat=DEFAULT_DATE_FORMAT] - The format to use for the output date.
 * @param {string} [params.inputFormat=''] - The format of the input date. If provided, it's used to parse the input date.
 * @param {boolean} [params.useTimezone=false] - Whether to convert the date to a different timezone.
 * @param {boolean} [params.keepLocalTime=false] - Whether to keep the local time when converting to a different timezone.
 * @param {string} [params.placeholder=''] - The placeholder to return if the input date is not valid or not provided.
 * @param {string} [params.timezone] - The timezone to convert the date to. If not provided, the timezone of the authenticated user's community is used.
 * @returns {string} The formatted date as a string. If the input date is not valid or not provided, it returns the placeholder.
 *
 * @example
 * // returns "2022-01-01"
 * dateFormatter({ value: new Date(2022, 0, 1), outputFormat: 'YYYY-MM-DD' })
 * // returns ""
 * dateFormatter({ value: 'invalid date', outputFormat: 'YYYY-MM-DD', placeholder: '' })
 * // returns "2022-01-01"
 * dateFormatter({ value: '01-01-2022', inputFormat: 'MM-DD-YYYY', outputFormat: 'YYYY-MM-DD' })
 * // returns ""
 * dateFormatter({ value: undefined, outputFormat: 'YYYY-MM-DD', placeholder: '' })
 * // returns "2022-01-01T00:00:00Z"
 * dateFormatter({ value: new Date(2022, 0, 1), outputFormat: 'YYYY-MM-DDTHH:mm:ssZ', useTimezone: true })
 */

export const dateFormatter = ({
    value,
    outputFormat = DEFAULT_DATE_FORMAT,
    inputFormat = '',
    useTimezone = false,
    keepLocalTime = false,
    placeholder = '',
    timezone = '',
}) => {
    if (!value) {
        return placeholder;
    }

    const momentDate = inputFormat ? moment(value, inputFormat) : moment(value);

    if (!momentDate.isValid()) {
        return placeholder;
    }

    const formattedDate = momentDate.format(outputFormat);

    if (useTimezone) {
        const timezoneResult = timezone || store.getters['auth/community']?.timezoneId;
        return momentDate.tz(timezoneResult, keepLocalTime)?.format(outputFormat);
    }

    return formattedDate;
};

export const timeDifferenceFormatter = (d1, d2 = new Date()) => {
    const units = {
        year: 24 * 60 * 60 * 1000 * 365,
        month: (24 * 60 * 60 * 1000 * 365) / 12,
        day: 24 * 60 * 60 * 1000,
        hour: 60 * 60 * 1000,
        minute: 60 * 1000,
        second: 1000,
    };
    const formatter = new Intl.RelativeTimeFormat('en', { numeric: 'auto' });
    const elapsed = new Date(d1).getTime() - d2;

    for (const u in units) {
        if (Math.abs(elapsed) > units[u] || u === 'second') {
            return formatter.format(Math.round(elapsed / units[u]), u);
        }
    }
};

export const weekDayByName = name => WEEKDAYS.indexOf(name);

export const addDayToDate = (value, days = 1) => {
    const currentDate = new Date(value);
    return currentDate.setDate(currentDate.getDate() + days);
};

export const getTodayDateOption = () => {
    const today = new Date().toISOString();
    return {
        id: today,
        value: dateFormatter({ value: today }),
    };
};

export const wrapDateWithStartTime = date => {
    if (moment(date, 'YYYY-MM-DD', true).isValid()) {
        return `${date}T00:00:00Z`;
    }
    return undefined;
};

export const wrapDateWithEndTime = date => {
    if (moment(date, 'YYYY-MM-DD', true).isValid()) {
        return `${date}T23:59:59Z`;
    }
    return undefined;
};

/**
 *
 * @param {object} timeObject time object in { hours, minutes } format
 * @returns time string in "hh:mm" format
 */
export const getTimeString = timeObject => {
    if (!timeObject) {
        return;
    }

    const { hours, minutes } = timeObject;

    return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
};

/**
 *
 * @param {object} timeObject time string in "hh:mm" format
 * @returns time object in { hours, minutes } format
 */
export const getTimeObject = timeString => {
    if (!timeString) {
        return;
    }

    const [hours, minutes] = timeString.split(':');

    return {
        hours: Number(hours),
        minutes: Number(minutes),
    };
};

/**
 * Check Zulu date format
 *
 * @param date ISO8601 string
 * @returns {string}
 */
export const checkFormatDate = date => {
    const checkFormatRegex = /^\d\d\d\d-\d\d-\d\dT\d\d:\d\d:\d\d(\.\d+)?$/.test(date);

    // Check if date has "Z" at the end, if not, add it
    // 2022-11-23T07:35:00 => 2022-11-23T07:35:00Z
    if (checkFormatRegex) {
        date = date + 'Z';
    }

    return date;
};

export const dateCalculator = (value, operator, quantity, duration) => {
    return moment.utc(value)[operator](quantity, duration).startOf('day').toISOString();
};
