import { useEmailbotStore } from '@/stores/emailbot/emailbot';
import EMAILBOT_PERMISSIONS from '@/components/emailbot/common/emailbot_permissions_constants';

const createPermissionChecker = permissionId => () => {
    const hasPermission = useEmailbotStore().hasPermission;
    return hasPermission(permissionId);
};

export default [
    {
        routeName: 'emailbot.bots.index',
        items: [
            {
                title: 'add new',
                routeName: 'emailbot.create',
                allowed: createPermissionChecker(EMAILBOT_PERMISSIONS.CREATE_EMAILBOT),
            },
        ],
    },
    {
        routeName: 'emailbot.templates.index',
        items: [
            {
                title: 'add new',
                routeName: 'template.create',
                allowed: createPermissionChecker(EMAILBOT_PERMISSIONS.CREATE_COMMUNITY_TEMPLATE),
            },
        ],
    },
];
