import { createPermissionChecker } from '@/router/iot/helper';

export default [
    {
        routeName: 'iot.keys.index',
        items: [
            {
                title: 'add keys',
                routeName: 'iot.keys.create',
                allowed: createPermissionChecker('LOCK_CREATE_KEY_CREDENTIALS'),
            },
            {
                title: 'import keys from csv',
                routeName: 'iot.keys.import',
                allowed: createPermissionChecker('LOCK_IMPORT_KEY_CREDENTIALS'),
            },
            {
                title: 'assign keys',
                routeName: 'iot.keys.assign',
                allowed: createPermissionChecker('LOCK_ISSUE_KEY_CREDENTIALS'),
            },
            {
                title: 'delete keys',
                routeName: 'iot.keys.delete',
                allowed: createPermissionChecker('LOCK_DELETE_FOB_CREDENTIALS'),
            },
        ],
    },
    {
        routeName: 'iot.locations.index',
        items: [
            {
                title: 'create location',
                routeName: 'iot.locations.create',
                allowed: createPermissionChecker('LOCATION_CREATE'),
            },
        ],
    },
    {
        routeName: 'iot.units.index',
        items: [
            {
                title: 'add location',
                routeName: 'iot.locations.create',
                allowed: createPermissionChecker('LOCATION_CREATE'),
            },
        ],
    },
    {
        routeName: 'iot.apartments-list.index',
        items: [
            {
                title: 'add location',
                routeName: 'iot.locations.create',
                allowed: createPermissionChecker('LOCATION_CREATE'),
            },
        ],
    },
    {
        routeName: 'iot.locations.details',
        items: [
            {
                id: 'iot-location-delete',
                title: 'delete',
                showConfirmation: true,
                confirmationMessage: 'are you sure you want to delete this location?',
                confirmBtnText: 'yes, delete',
                cancelBtnText: 'no, go back',
                confirmationType: 'warning',
                allowed: createPermissionChecker('LOCATION_DELETE'),
            },
        ],
    },
    {
        routeName: 'iot.custom_group_access.index',
        items: [
            {
                title: 'add new group',
                routeName: 'iot.custom_group_access.create',
                allowed: createPermissionChecker('CUSTOM_USER_GROUP_CREATE'),
            },
        ],
    },
    {
        routeName: 'iot.custom_group_access.details',
        items: [
            {
                title: 'edit group',
                routeName: 'iot.custom_group_access.edit',
                params: {
                    id: '{id}',
                },
                allowed: createPermissionChecker('CUSTOM_USER_GROUP_UPDATE'),
            },
        ],
    },
    {
        routeName: 'iot.auto_open.index',
        items: [
            {
                title: 'add new',
                routeName: 'iot.auto_open.create',
                allowed: createPermissionChecker('PDK_AUTO_OPEN_CREATE'),
            },
        ],
    },
    {
        routeName: 'iot.passes.index',
    },
    {
        routeName: 'iot.passes.details',
    },
    {
        routeName: 'iot.passes.summary',
        items: [
            {
                id: 'copy-pass-link',
                title: 'copy pass link',
                isRegular: true,
            },
        ],
    },
    {
        routeName: 'iot.integrated_community.details',
        items: [
            {
                title: 'download report',
                isDownload: true,
            },
        ],
    },
];
