import LEASING_PERMISSIONS from '@/utils/constants/leasing_permissions_constants';
import { useLeasingStore } from '@/stores/leasing';

const routes = [
    {
        name: 'notice_to_vacate.index',
        path: '/notice-to-vacate',
        component: () => import('@/views/resident_lifecycle/notice_to_vacate/Index'),
        meta: {
            loadInModal: true,
            actions: true,
            breadcrumbName: 'notice to vacate',
            title: 'notice to vacate',
            size: 'normal',
            flowStartsWith: 'notice_to_vacate.index',
            product: 'move-in',
            requiresCustomer: true,
            requiresCommunity: true,
            permissionChecker: () => {
                const leasingStore = useLeasingStore();
                return leasingStore.hasPermission(LEASING_PERMISSIONS.LEASE_NTV);
            },
        },
    },
    {
        name: 'notice_to_vacate.generalInformation',
        path: '/general-information/:occupancy_id?',
        component: () => {
            return import('@/views/resident_lifecycle/notice_to_vacate/GeneralInformation');
        },
        meta: {
            loadInModal: true,
            actions: true,
            breadcrumbName: 'general information',
            title: 'general information',
            size: 'normal',
            flowStartsWith: 'notice_to_vacate.index',
            product: 'move-in',
            permissionChecker: () => {
                const leasingStore = useLeasingStore();
                return leasingStore.hasPermission(LEASING_PERMISSIONS.LEASE_NTV);
            },
        },
    },
    {
        name: 'notice_to_vacate.forwardingAddress',
        path: '/forwarding-address/:startDate/:endDate',
        component: () => {
            return import('@/views/resident_lifecycle/notice_to_vacate/ForwardingAddress');
        },
        meta: {
            loadInModal: true,
            actions: true,
            breadcrumbName: 'forwarding address',
            title: 'forwarding address',
            size: 'normal',
            flowStartsWith: 'notice_to_vacate.index',
            product: 'move-in',
            permissionChecker: () => {
                const leasingStore = useLeasingStore();
                return leasingStore.hasPermission(LEASING_PERMISSIONS.LEASE_NTV);
            },
        },
    },
    {
        name: 'notice_to_vacate.feesAndProrating',
        path: '/fees-and-prorating/:startDate/:endDate/:source',
        component: () => {
            return import('@/views/resident_lifecycle/notice_to_vacate/FeesAndProrating');
        },
        meta: {
            loadInModal: true,
            actions: true,
            breadcrumbName: 'fees and prorating',
            title: 'fees and prorating',
            size: 'normal',
            flowStartsWith: 'notice_to_vacate.index',
            product: 'move-in',
            permissionChecker: () => {
                const leasingStore = useLeasingStore();
                return leasingStore.hasPermission(LEASING_PERMISSIONS.LEASE_NTV);
            },
        },
    },
    {
        name: 'notice_to_vacate.addCharges',
        path: '/add-charges',
        component: () => {
            return import('@/views/resident_lifecycle/notice_to_vacate/AddCharges');
        },
        meta: {
            loadInModal: true,
            actions: true,
            breadcrumbName: 'add charges or credits',
            title: 'add charges or credits',
            size: 'normal',
            flowStartsWith: 'notice_to_vacate.index',
            product: 'move-in',
            permissionChecker: () => {
                const leasingStore = useLeasingStore();
                return leasingStore.hasPermission(LEASING_PERMISSIONS.LEASE_NTV);
            },
        },
    },
    {
        name: 'notice_to_vacate.cancelNtv',
        path: '/cancel-ntv/:notice_to_vacate_id',
        component: () => {
            return import('@/views/resident_lifecycle/notice_to_vacate/CancelNTV');
        },
        meta: {
            loadInModal: true,
            actions: true,
            breadcrumbName: 'cancel ntv',
            title: 'cancel ntv',
            size: 'normal',
            flowStartsWith: 'notice_to_vacate.index',
            product: 'move-in',
            permissionChecker: () => {
                const leasingStore = useLeasingStore();
                return leasingStore.hasPermission(LEASING_PERMISSIONS.LEASE_NTV);
            },
        },
    },
];

export default routes;
