export default {
    guest_cards: {
        getList: ['GET', '/v2/guest-cards'],
        getOne: ['GET', '/v2/guest-cards/{id}'],
        create: ['POST', '/v2/guest-cards'],
        update: ['PUT', '/v2/guest-cards/{id}'],
        close: ['PUT', '/v2/guest-cards/{id}/close'],
        createApplication: ['PUT', '/v2/guest-cards/{id}/applicant'],
    },

    residents: {
        getList: ['GET', '/v2/residents'],
        create: ['POST', '/v2/residents'],
        update: ['PUT', '/v2/residents/{id}'],
        delete: ['DELETE', '/v2/residents/{id}'],
    },

    occupants: {
        getList: ['GET', '/v2/occupants'],
        create: ['POST', '/v2/occupants'],
        update: ['PUT', '/v2/occupants/{id}'],
        delete: ['DELETE', '/v2/occupants/{id}'],
    },

    pets: {
        getList: ['GET', '/v2/pets'],
        create: ['POST', '/v2/pets'],
        update: ['PUT', '/v2/pets/{id}'],
        delete: ['DELETE', '/v2/pets/{id}'],
    },

    tours: {
        getList: ['GET', '/v2/tours'],
        create: ['POST', '/v2/tours'],
        update: ['PUT', '/v2/tours/{id}'],
        delete: ['DELETE', '/v2/tours/{id}'],
        getTimeSlot: ['GET', '/v2/tours/available-time-slots'],
    },

    notes: {
        getList: ['GET', '/v2/notes'],
        create: ['POST', '/v2/notes'],
        update: ['PUT', '/v2/notes/{id}'],
        delete: ['DELETE', '/v2/notes/{id}'],
    },

    units: {
        getList: ['GET', '/v2/units'],
        getPriceMatrix: ['GET', '/v2/units/{id}/price-matrix'],
    },

    lease_agents: '/v2/users',
    community_settings: '/settings',

    referral_sources: {
        get: ['GET', '/settings/referrals'],
        update: ['PUT', '/settings/referrals'],
    },

    close_reasons: {
        get: ['GET', '/settings/reasons'],
        update: ['PUT', '/settings/reasons'],
    },

    tasks: 'tasks',
    // UPDATE STATUS OF TASK FROM OPEN TO CLOSED
    taskCompleted: '/task-completed',
    reopenTask: '/task-reopen',
};
